.progressbar{
	position: absolute;
	overflow: hidden;
	width: 360px;
	height: 20px;
	border-radius: 5px;
	background-color: #eee;
}

/* .progressPercent{
	font-weight: 600;
	font-family:'Franklin Gothic Medium';
	position: absolute;
	left: 20%;
	top: 20%;
	transform: translate(-50%,-50%);
	color: #eee;
	text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
} */


@media screen and (min-width:280px) and (max-width:575px){
	.progressbar{
		width: 180px;
		margin-left:-50px;
	}
}

@media screen and (min-width:767px) and (max-width:992px){
	.progressbar{
		width: 260px;
	}
}