body{
  background-color: white;
}
.TopHeader{
  background-color: black;
  color:white;
  font-size:12px;
}
.faIcon{
  margin-top:20px;
}
.Main2ndHeader{
   background-color: black;
}
#NavCall{
   border-radius:50%;
  background-color:red;
  padding:15px 15px;
  color:white;
}
#NavText{
  color:white;
  /* position: relative;
  top:-20px; */

}
#HeaderFont{
  color: #DD3333;
}
#Header-Content{
   font-weight: 700;
   font-size:24px;
   color: #DD3333;
   margin-left:-340px;
   margin-top:10px;
   margin-right:100px;
}
.HeaderOpt{
  font-size: 16px;
  font-weight:600;

}
#Navlink{
  text-decoration: none;
  list-style: none;
  font-weight:600;
  font-size:22px;
  color: white
}
.leftbutt{
  position:absolute;
  z-index: 1;
  top:230px;
  left:100px;
  height:80px;
  width:80px;
  border:none;
  border-radius:100%;
  background-color: rgba(245, 245, 245, 0.477);
  cursor:pointer;
  margin-top:13px;
}
.RightButt{
  position:absolute;
  z-index: 1;
  top:260px;
  left:90%;
  height:80px;
  border:none;
  width:80px;
  border-radius:100%;
  background-color: rgba(245, 245, 245, 0.477);
  cursor:pointer;
  margin-top:-10px;
}
.mySwiper{
  height:80vh;
}
.HomeAboutMain{
  margin-top:100px;
}
.HomeFourthMain{
  margin-top:80px;
  background-image: url('../src/Assests/Images/service_bg.jpg');
  width:100%;
}
.HomeFifthLeftComp{
  background-image: url('./Assests/Images/service_bg.jpg');
  width:100%;
}

#HomeFifthCardComp{
   width: 12rem;
   height: 165px;
   margin: 10px ;
}
#HomeFifthCardComp2{
   width: 12rem;
    height: 165px; 
    margin: 10px;
}
#HomeFifthImage{
 height: 60px;
  width: 60px;
  margin: 10px 50px;
}
.HomeSeventhComp{
  background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url('./Assests/Images/Home-EighthCompImg.jpg');
  height:50vh;
  width:100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#MainFooter1{
  background-image:url('./Assests/Images/Background-Img.png');

  width:100%;
}
.SubFooter{
  background-color:rgb(231, 174, 20);
  height:15vh;
  width:100%;
}

#AboutHr{
height:10px;
width:50px;
background-color:red;

}

.AboutFirstComp{
  background-image:url('./Assests/Images/Background-Img.png');

}
.AboutFirstSpan{
  background-color: #FFB703;
  border-radius: 50%;
  width: 7px;
  height: 7px;
}

.AboutFirstSpan::after{
  content: "";
  position: absolute;
  background-color: #ffb703;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  right: -3px;
  top: 5px;
}
.AboutThirdMain{
  background-image: url('./Assests/Images/AboutThirdMainImg.jpg');

  width:100%;
}
.AboutFifthComp{
  background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url('./Assests/Images/AboutFifthImg.jpg');
  padding: 20px;
  width:100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

 #FireExtHr{
  height:10px;
  width:50px;
  background-color:red;
  margin-top:30px;
  margin-left:60px;
 }
 .FireExtText{
  margin-top:60px;
 }
.FireExtinguishersColImg{
  background-image:linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),url('./Assests/Images/FireExtinguishersImg1jpg.jpg') ;
  height:450px;
  width:400px;
}
.FireExtinguishersColImg1{
  background-color: #010D14;
  height:230px;
  width:200px;
}


.googlemap {
  background-color: blue;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  padding-bottom: 0;
}

.google-iframe {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  border: 2px solid red;
}
 /* ---------------------------------------------------- */
 /* .ContIFrame{
  margin-left:60px;
 } */
/* #MapCard{
 margin-left:-60px;
} */
.google-iframe{
  height:550px;
  width:385px;
}
.googlemap{
   height:550px;
  width:385p
}
 .ContactMapSec{
  /* max-width :16rem;
  margin-left: 60px; */
  /* z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0px;
    border-width: 0px;
    margin: 0px;
    left: 189px;
    top: 192px;
    touch-action: pan-x pan-y */
 }
 .Contact3Img{
  height:550px;
  width:400px;
 }
 .Contact3Img1{
  height:550px;
  width:400px;
 }
.ContactHR{
 height:10px;
  width:50px;
  background-color:red;
  margin-left:50px;
}
.ContactTitle{
  margin-left:-60px;
  margin-top:50px;
}
.ContactPara{
  margin-left:-130px;
  font-size:17px;
}

  .ContactMap{
    width: 400px;
    height:550px;
    margin-left:20px;
  }
.ContCol2Main{
   background-image:linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.4)),url('./Assests/Images/ContSecImg.png');
   background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  height:70vh;
}
.ContactThirdRow{
    
}
.Contact4Card{
 width:20rem;
 background-color:#010D14;
 color:white;
}
.Contact4Img{
  margin:0px 90px;
  height:50px;
  width:55px;
}
.Cont3Cardd{

}
 .SerAidHR{
  height:10px ;
  width:50px;
  background-color:yellow;
  margin-left:50px;
 }
 .SerAidTitle{
   margin-top:50px;
   margin-left:30px;
 }
 .SerFightHR{
 height:10px ;
 width:50px ;
 margin-left:60px;
 background-color:yellow;
 }
 .SerFightTitle{
    margin-top:50px;
 }
.SerAlarmHR{
 height:10px ;
 width:50px ;
 background-color:yellow;
 margin-left:50px;
 margin-top:50px;

}
.SerAlarmTitle{
  margin-left:20px;
  margin-top:50px;
}

 .SerFireHR{
  height:10px ;
  width:50px ;
  background-color:yellow;
  margin-left: 60px;
 }
 .SerFireTitle{
  margin-top:40px;
 }
.PersHR{
  height:10px;
   width:50px;
   margin-left:50px;
   background-color:red;
}
.PersTitle{
  margin-left:-50px;
  margin-top:40px;
}
.PersPara{
  margin-left:90px;
  margin-top:40px;
}
 .ConvenHR{
  height:10px; 
  width:50px; 
  background-color:red;
  margin-left:60px;
 }
 .ConvTitle{
   margin-top:50px;
 }
.FirstAddressHR{
 height:10px;
  width:50px; 
  background-color:red;
  margin-left:60px;
}
.FirstAddressTitle{
  margin-top:50px;
}

.FireHydPara{
  margin-left:90px;
  margin-top:50px;
}
 .FireHydHR{
  height:10px;
  width:50px ; 
  background-color:red;
  margin-left:50px;
 }

.ContCol2Main{
  background-color:#010D14;
  color:white;
}
.ContMainDiv{
  /* margin-left:160px; */
}
/* .HeaderDropdown{
  display: none;
  position:absolute;
  top:60px;
}
.HeaderDropdown:hover{
   position:absolute;
   top:60px;
   display: block;
} */


.dropdown_menu1{
  display:none;
  margin-left:-20px;
}
#CarddSize{
  width: 18rem;
}
.dropdown_menu1 a{
  text-decoration: none;
  transition: 0.8s;
  list-style: none;
  color: black;
}
.dropdown_menu1 a:hover{
   color:red;
}
.navlink:hover .dropdown_menu1{
  z-index: 2;
  transition: 0.8s;
 display:block;
 text-decoration: none;
 list-style: none;
 position:absolute;
 background-color:white;
 padding: 10px 30px 20px 10px;
}
.li1{
    padding: 14px 15px;
    font-size:15px;
}
.CardImgRes{
  width: 18rem;
  background-color:'#010D14';
   color:white;
}


.SwiperWidth{
  width:6rem;
}
.CardWidth{
  width:6rem;
}
.mySwiper{
  width: 58%;
   height: 90px;
  }
















@media screen and (min-width:279px) and (max-width:575px){
  #MainFooter1{

  }
  .HomeFourthMain{

    width:100%;   

  }
  #RowFourthComp{
    /* gap:20px; */
  }
  .HomeFifthLeftComp{

  }
  #CarddSize{
    width:14rem;
  }
  #HomeFifthCardComp{
    margin-left:70px;
  }
  #HomeFifthCardComp2{
    margin-left:70px;
  }
  #HomeFifthImage{
    margin-left:40px;
  }
  #HomeFifthlastimg{
    margin-left:70px;
  }
  .HomeSwiper{
    height:100px;
    width:100%;
  }
  #AboutHr{
    margin-left:160px;
  }
  .Abouttext{
    /* margin-left:130px; */
  }
  #FireExtHr{
    margin-left:170px;
  }
  .FireExtText{
    margin-left:140px;
  }
  .FireExtPara{
    text-align: center;
    margin-left:50px;
  }
  .FireHydHR{
    margin-left:170px;
    margin-top:70px;
  }
  .FireHydTtitle{
    margin-top:50px;
    margin-left:170px;
  }
  .FireHydPara{
     margin-left:210px;
  }
  .FirstAddressHR{
    margin-left:160px;
    margin-top:70px;
  }
  .FirstAddressTitle{
    margin-left:190px;
  }
  .ConvenHR{
    margin-left:170px;
  }
  .ConvTitle{
    margin-left:180px;
    margin-top:50px;
  }
  .PersHR{
    margin-left:140px;
  }
  .PersTitle{
    margin-left: 110px;
  }
  .PersPara{
    margin-left:190px;
  }
  .SerFireHR{
    margin-left:160px;
    margin-top:60px;
  }
  .SerFireTitle{
    margin-left:150px;
  }
  .SerAlarmHR{
    margin-left:160px;
  }
  .SerAlarmTitle{
    margin-left:160px;
  }
  .SerFightHR{
    margin-left:160px;
  }
  .SerFightTitle{
    margin-left: 150px;
    }
    .SerAidHR{
      margin-left: 160px;
    }
    .SerAidTitle{
      margin-top:50px;
      margin-left:170px;
    }
    .ContactHR{
      margin-left:120px;
    }
    .ContactTitle{
      margin-left:40px;
    }
    .ContactPara{
      font-size:12px;
      margin-left:10px;
    }
    .ContactThirdRow{
      margin-left:-20px;
    }
    .ContactMap{
      width : 200px;
      margin-left:0px;
    }
    
    .Contact3Img{
      width:250px;
    }
    .Contact3Img1{
      width:250px;
    }
    /* .ContIFrame{
      margin-left:-10px;
    } */
  .ContactMapSec{
    /* width:100px; */
  }
  #MapCard{
    width:100px;
  }
  iframe{
    margin-left:-29px;
    height:500px;
    width:245px;
  }
  .googlemap{
    width:350px;
  }
  .ContMainDiv{
      margin-left:-0px;
  }
  .Cont3Cardd{
    margin-left:0px;
  }
.CardImgRes{
  width: 18rem;
  background-color:'#010D14';
   color:white;
}
}

@media screen and (min-width:750px) and (max-width:1040px){
  .HomeSwiper{
    height:100px;
    width:100%;
  } 

}

@media screen and (min-width:756px) and (max-width:1350px){
  .RightButt{
    display:none;
  }
  .leftbutt{
    display:none;
  }
}